import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { Container } from "react-bootstrap"

const IndexPage2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: { eq: "PST_Convergence2016_681.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 50, 
              webpOptions: {quality: 50}, 
              width: 2000,
              placeholder: BLURRED,
              blurredOptions: {width: 1000},
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);
  // const crowdImage = getImage(crowd);

  return (
    <Layout>
      <SEO title="Event Creation | ECI Services" />
      <BgImage image={pluginImage} className="subhead">
        <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <h1>Event Creation</h1>
              
            </Container>
          </div>
        </div>
      </BgImage>
      <Container className="singleCol">
        <h2 className="orangeBarBelow mb-4">Create an event that engages your audience and magnifies your message.</h2>
        <p>You have business goals to meet, people to incent and a great event idea that will bring the two together. The next step is finding a partner to listen and understand your vision, who is capable of managing the details to make it come to life. Enter <strong>Event Consulting</strong>… we are experts at blending details, creativity and resources to accomplish even the most complex event.</p>
        
          <p>
        <StaticImage
              src="../../images/event_creation_03.jpg"
              width={890}
              quality={72}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Corporate Meeting"
            />
        </p>
        </Container>
    </Layout>
          
  )
}

export default IndexPage2